@import "./colors.scss";

html,
:root {
  font-size: 14px !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  color: white;
  display: inline-block;
}
a:hover {
  text-decoration: none !important;
  color: white;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
button {
  outline: none;
  border: none;
  background-color: transparent;
}

.border {
  border: 1px solid $gray;
}



.selected-border {
  border-color: $blue !important;
}


.border-y {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}
.border-x {
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}
.border-t {
  border-top: 1px solid $gray;
}
.border-b {
  border-bottom: 1px solid $gray;
}
.text-normal {
  font-weight: 400 !important ;
}
.text-bold {
  font-weight: 600;
}
.text-bolder {
  font-weight: 700;
}

.border-circle {
  border: 3px solid $gray;
  border-radius: 50%;
}
.header {
  background-color: $dark-blue;
  border-bottom: 1px solid $gray;
}
.text-orange{
  color:#F49D4B !important
}
