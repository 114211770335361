.col-containt
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin:12px  0 !important;
   
    background: #324356 !important;
    color:#fff !important;
    vertical-align: bottom !important;
    div{
    button{
        height: 100% !important;
        margin: 0 0 0 5px;
        border-radius: 0 !important;
        padding-top: 9px ;
        padding-bottom: 9px ;
        
    }
    .num{
        display: inline-block;
        height: 25px ;
        width: 25px ;
        border-radius: 50%;
        background: #fff;
        color:#324356;
        text-align: center;
        font-weight: 700;
        margin: 0 8px ;
    }
    
}
.last{
    margin-right:  15px ;
    margin-left:  0px ;
    font-size: 16px !important;
    cursor: pointer;

    i{
        margin: 0px 8px !important;
        font-size: 18px !important;
    }
   
}

}
.card{
    border: none !important;
    background-color: transparent !important;
}
.card-body{
    padding:0 !important;
    margin: 0 !important;
    border: none !important;

}
.table{

    border: none !important;
}

.CollapsePlusIcon {
    margin-left: 0px !important;
    margin-right: 10px !important;
    margin-bottom: 0px !important;
}

html[dir="rtl"] .CollapsePlusIcon {
    margin-left: 10px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
}

.editIcon {
    margin-left:  0px !important;
    margin-right:  15px !important;
}

html[dir="rtl"] .editIcon {
    margin-left:  15px !important;
    margin-right:  0px !important;
}