// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-300: #acacac;
$gray-400: #dee1e3;
$gray: #C6CBD0;
$dark-blue: #324356;
$blue: #1677F6;
$red: #ff5f59;
$orange: #f28f08;
$green-100: #00b43740;
$green: #00b437;
$goldColor: gold;
$orangColor: #dc7542;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
$brwon: #656565;
// typogarphy
.text_blue-dark{
    color:$dark-blue ;
}
.text-blue{
    color:$blue ;
}
.text-brwon{
    color:$brwon ;
}