// Directions


@import "~bootstrap/scss/bootstrap";
@import "base/rtl.scss";

//components files
@import "modules/component/component.scss";




//base files
@import "base/general.scss";
@import "base/colors.scss";

//modules files
@import "modules/companies/companies.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "modules/companies/tripsData.scss";
@import "modules/companies/pcrReservation.scss";
//Check Me
//-----------Dahboard
@import "modules/dashboard/dashboard.scss";

//-----------Travelers
@import "modules/customers/customers.scss";

//------------workTeam
@import "modules/workTeam/workTeam.scss";
@import "modules/workTeam/Collapse.scss";


//------------Payments
@import "modules/payments/payments.scss";


@import "shared/inputs.scss";
@import "shared/_loader.scss";




//shared files


// public
body{
    background-color: #F9F9F9;
}


.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1520px !important;/** 1320px*/
    padding-left: 2.5rem !important;
 padding-right: 2.5rem !important;

}

.control-field__input{
    border:none !important
}

.header-icons{
    width: 320px;
    button{
        display: inline-block;
        .fa-sign-out-alt{
            font-size: 25px;
            color: #007bff;
        }
       
    }
    .dropdown{
        display: inline-block;
    }
}

@media print {
    header, footer, aside, form {
        display: none;
    }
    .header-container {
        display: none;
    }
    
}