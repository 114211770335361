.bg_table{
    background-color: #656565 !important;
    color: white;
    
}
td{
    border: 0px !important;
}
tr:nth-child(even) {background-color: #EDF2F7;}

.list_header{
    background-color:#F2F2F2 ;
    
    
}

.list_header--bottom{
    background-color:#F2F2F2 ;
    border-bottom: 2px solid #D6D6D6;
}
.list_content{
    padding: 0rem 10rem;
}


/* .greenStatus{
    border-left: 4px solid #00B437;
    border-right:none;
}
[dir="rtl"] .greenStatus{
    border-right: 4px solid #00B437;
    border-left:none;
}
.orangeStatus{
    border-left: 4px solid #F28F31;
    border-right:none;
}
[dir="rtl"] .orangeStatus{
    border-right: 4px solid #F28F31;
    border-left:none;
}
.redStatus{
    border-left: 4px solid #FF5F59;
    border-right:none;
}
[dir="rtl"] .redStatus{
    border-right: 4px solid #FF5F59;
    border-left:none;
} */
/* .transLogo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccd5db;
    margin-inline-end: 3px;
}
 */