.table-travelers{
    th{
        font-size: 12px !important;
    }
    tr{
    td {
        color: #656565 ;
        font-size: 13px !important;
        font-weight: 600 !important;
    }
}
}
html[dir="rtl"]  .border-green
{
    border-right: 2px solid green !important;
}
html[dir="ltr"]  .border-green
{
    border-left: 2px solid green !important;
}
