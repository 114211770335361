
// body{
//     background-image: url("../../../assets/images/paymentBg.png");
//     background-position:bottom bottom ;
    
//     background-repeat: no-repeat;
//     background-size: cover;
// }

.payment-cost
{
    background-image: url("../../../assets/images/paymentBg.png");
    background-position: -50% 1% ;
    
    background-repeat: no-repeat;
    background-size: cover;
    
   
    
    

    .boxs{
        .box{
            padding: 15px !important;
            background-color: #fff !important;
            border-radius: 8px !important;
           

            .layout{
                background-color: #DEEAF3 ;
                border-radius: 8px !important;
                padding: 10px 5px;
                
                p{
                    margin: 0!important;
                }

            }
        }
    }
}
.comany-medical {
    .actions{
        button{
            font-size: 15px ;
        }
        i{
            font-size: 18px !important;
        }
    }
   
    
}