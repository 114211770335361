.instructions {
  background-color: #EDF2F7;

}
.text-color {
  color: #656565;
}
.card {
  border: 0px solid #ccd5db;
  border-top: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.bg-brwon{
  background-color: #D6D6D6;
}